let saveButton = document.querySelector('.userdata .button');

saveButton.addEventListener('click', ()=> {
    let username = document.querySelector('.userdata .username').value;

    localStorage.setItem('username', username);

    document.querySelector('.userdata').classList.remove('active');

    location.reload();
})