const addButton = document.querySelector('.addWeight');

const saveButton = document.querySelector('.newWeightContainer .button');

addButton.addEventListener('click', ()=> {
    let newWeightContainer = document.querySelector('.newWeightContainer');
    if (newWeightContainer.classList.contains('active')){
        newWeightContainer.classList.remove('active');
    } else {
        newWeightContainer.classList.add('active');
    }
})


saveButton.addEventListener('click', () => {
    let username = localStorage.getItem('username');
    let date = document.querySelector('.newWeightContainer .newDate').value;
    date = formatDate(date);
    let weight = document.querySelector('.newWeightContainer .newWeight').value.replace(',', '.');




    if (date != "" && weight != ""){
        saveData(username, date, weight);
    }
});


function formatDate(inputDate) {
    // Die Eingabe im Format YYYY-MM-DD in ein Date-Objekt umwandeln
    const date = new Date(inputDate);

    // Die einzelnen Teile des Datums extrahieren
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Monate sind 0-indexiert
    const year = date.getFullYear();

    // Im gewünschten Format zurückgeben
    return `${day}.${month}.${year}`;
}
function saveData(username, date, weight) {
    let xhr = new XMLHttpRequest();
    let url = 'https://weight.johannesbloechl.de/save_data.php';
    let params = `username=${encodeURIComponent(username)}&date=${encodeURIComponent(date)}&weight=${encodeURIComponent(weight)}`;
    
    xhr.open('POST', url, true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    
    xhr.onreadystatechange = function() {
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
            console.log('Data saved successfully:', xhr.responseText);
            document.querySelector('.newWeightContainer').classList.remove('active');
            location.reload();
        } else if (xhr.readyState === XMLHttpRequest.DONE) {
            console.error('An error occurred:', xhr.statusText);
        }
    };

    xhr.send(params);
}
