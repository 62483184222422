let data = [];
let weekAverage = {};
let weekEndIndexes = {};

let today = new Date();
document.querySelector('.actDate').innerText = today.getDate() + '.' + (today.getMonth() + 1) + '.' + today.getFullYear();

if (localStorage.getItem('username')) {
    // Create a new XMLHttpRequest object
    var xhr = new XMLHttpRequest();

    // Configure it: POST-request for the URL
    xhr.open("POST", "https://weight.johannesbloechl.de/get_data.php", true);

    // Set up the callback function to log the response data
    xhr.onreadystatechange = function() {
        if (xhr.readyState == 4 && xhr.status == 200) {
            console.log("Response received:");
            data = JSON.parse(xhr.responseText); // Parse the JSON response
            console.log(data); // Log the parsed data
            magicthing();
            data.reverse(); // Datenliste umkehren, damit sie mit der frühesten KW beginnt

            document.querySelector('.actWeight').innerText = data[0].weight + ' KG';
            document.querySelector('.counter').innerText = data.length;

            displayDataWithAverages(); // Daten und Durchschnitte ausgeben
        } else if (xhr.readyState == 4) {
            console.log("Request failed. Status: " + xhr.status);
        }
    };

    // Prepare the POST data
    let username = localStorage.getItem('username');
    var postData = "username=" + username;

    // Set the request header for POST data
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

    // Send the request with the POST data
    xhr.send(postData);

} else {
    document.querySelector('.userdata').classList.add('active');
}

function magicthing() {
    data.forEach(function(entry) {
        var weekNumber = getWeekNumber(entry.date);
        recordWeightForWeek(weekNumber, parseFloat(entry.weight));
        entry.weekNumber = weekNumber; // Kalenderwoche zur Datenstruktur hinzufügen
    });
}

function getWeekNumber(dateString) {
    var date = new Date(dateString.split('.').reverse().join('-')); // Convert to Date object
    var firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    var pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}

function recordWeightForWeek(weekNumber, weight) {
    if (!weekAverage[weekNumber]) {
        weekAverage[weekNumber] = [];
    }
    weekAverage[weekNumber].push(weight);
}

function calculateAverageForWeek(weekNumber) {
    let totalWeight = weekAverage[weekNumber].reduce((sum, weight) => sum + weight, 0);
    return totalWeight / weekAverage[weekNumber].length;
}

function displayDataWithAverages() {
    const weightlistElement = document.querySelector('.weightlist'); // Get the container element

    let currentWeek = data[0].weekNumber;

    data.forEach(function(entry, index) {
        // Create a weight item for the current data entry
        createWeightItem(weightlistElement, entry.weekNumber, entry.date, entry.weight);

        // Check if the current day is Sunday
        const [day, month, year] = entry.date.split('.').map(Number);
        const parsedDate = new Date(year, month - 1, day);
        const weekDay = parsedDate.getDay(); // Sunday is 0, Monday is 1, ..., Saturday is 6

        // If it's the last entry of the data array or if the next entry is from a different week, calculate and add the average
        if (index + 1 === data.length || (data[index + 1] && data[index + 1].weekNumber !== entry.weekNumber)) {
            let averageWeight = calculateAverageForWeek(entry.weekNumber);
            createWeightItem(weightlistElement, entry.weekNumber, 'Durchschnitt', averageWeight.toFixed(2), true); // Pass true to indicate it's an average item
            currentWeek = data[index + 1] ? data[index + 1].weekNumber : null;
        }
    });
}

function createWeightItem(container, weekNumber, date, weight, isAverage = false) {
    // Create the main weight item container
    const weightItem = document.createElement('div');
    weightItem.className = 'weightItem';

    weightItem.setAttribute('data-date', date);
    

    // Set background color if it's an average item
    if (isAverage) {
        weightItem.style.backgroundColor = '#ff2d55';
        weightItem.style.color = '#ffffff';
    }

    // Create the left side of the weight item
    const leftDiv = document.createElement('div');
    leftDiv.className = 'left';
    const weekP = document.createElement('p');

    if (isAverage) {
        // If it's an average, show the calendar week
        weekP.textContent = 'KW ' + weekNumber;
    } else {
        // If it's not an average, show the day of the week
        const [day, month, year] = date.split('.').map(Number);
        const parsedDate = new Date(year, month - 1, day);
        const weekDay = parsedDate.toLocaleDateString('de-DE', { weekday: 'long' });
        weekP.textContent = weekDay;
    }

    const dateP = document.createElement('p');
    dateP.textContent = date;

    leftDiv.appendChild(weekP);
    leftDiv.appendChild(dateP);

    // Create the right side of the weight item
    const rightDiv = document.createElement('div');
    rightDiv.className = 'right';
    const weightP = document.createElement('p');
    weightP.textContent = weight + ' KG';

    rightDiv.appendChild(weightP);

    // Create the delete button
    const deleteButton = document.createElement('div');
    deleteButton.className = 'deleteButton';

    // Append the delete button to the right div
    rightDiv.appendChild(deleteButton);

    // Append the left and right divs to the main weight item container
    weightItem.appendChild(leftDiv);
    weightItem.appendChild(rightDiv);

    // Append the weight item to the container
    container.appendChild(weightItem);
}

