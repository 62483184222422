
setTimeout(function(){
    let weightItems = document.querySelectorAll('.weightItem');
    if (weightItems.length > 0){
        weightItems.forEach(weightItem => {
            weightItem.addEventListener('click', () => {
                if (confirm(`Möchtest du den Eintrag vom wirklich löschen?`)) {
                    let username = localStorage.getItem('username');
                    let date = weightItem.getAttribute('data-date');
                    deleteData(username, date);
                }
            })
        })
    }
},2000)


function deleteData(username, date) {
    let xhr = new XMLHttpRequest();
    let url = 'https://weight.johannesbloechl.de/delete_data.php';
    let params = `username=${encodeURIComponent(username)}&date=${encodeURIComponent(date)}`;
    
    xhr.open('POST', url, true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    
    xhr.onreadystatechange = function() {
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
            console.log('Record deleted successfully:', xhr.responseText);
            // Optional: Nach erfolgreichem Löschen die Ansicht aktualisieren
            location.reload();
        } else if (xhr.readyState === XMLHttpRequest.DONE) {
            console.error('An error occurred:', xhr.statusText);
        }
    };

    xhr.send(params);
}
